
import React, { useEffect} from "react";
import { navigate } from "gatsby";
import Navbar from "../components/Navbar";
import SEO from "../components/SEO";





export default function NotFoundPage() {
// on component did mount wait 6 secs then navigate homoe
  useEffect(() => {
    setTimeout(() => {
      navigate("/"); // redirecting to home page
    }, 6000)
  }, []);

  return (
    <div className='backgroundColor'>
      <Navbar />
      <SEO title='Not Found Page' description='the page you requested was not found'></SEO>
        <div className='h-screen w-full text-center pt-20'>
          <span className='mt-40'>
          <h1 className='text-white'>The Page you requested was not found</h1>
          <div className='h2 text-white'>¯\_(ツ)_/¯</div>
        <p className='text-white'>You will be redirected shortly</p>
      </span>
        </div>
    </div>
  );
}

